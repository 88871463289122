import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { Layout } from "../components/Layout";
import { ArrowRight, ArrowLeft } from "react-feather";

export default ({ data }) => {
  const { frontmatter, body, fields } = data.mdx;

  const [activeStep, setActiveStep] = useState(0);
  const [isLastStep, setIsLastStep] = useState(false);
  const [isFirstStep, setIsFirstStep] = useState(false);

  const previousStep = () => {
    setActiveStep(activeStep - 1);
  };
  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    const steps = Array.from(document.getElementsByClassName("step"));
    if (activeStep == 0) {
      setIsFirstStep(true);
    } else {
      setIsFirstStep(false);
    }
    if (activeStep == steps.length - 1) {
      setIsLastStep(true);
    } else {
      setIsLastStep(false);
    }
    steps.map(step => {
      if (step.id == activeStep) {
        step.classList.add("current");
      } else {
        step.classList.remove("current");
      }
    });
  }, [activeStep]);

  return (
    <Layout
      pageTitle={`${frontmatter.title} - atölyeler`}
      pageDescription={frontmatter.description}
      isArticle={true}
      modifiedDate={frontmatter.lastUpdated}
      path={`/workshops/${fields.slug}`}
    >
      <div className="blog-post tutorial">
        <h1>{frontmatter.title}</h1>
        <div className="post-meta-data">
          <span className="post-date">
            <strong>son güncelleme:</strong> {frontmatter.lastUpdated}
          </span>
        </div>
        <MDXRenderer>{body}</MDXRenderer>
      </div>
      <nav className="pagination">
        <ul>
          {!isFirstStep && (
            <li className="previousStep">
              <a href="#" onClick={previousStep}>
                <ArrowLeft />
                önceki adım
              </a>
            </li>
          )}
          {!isLastStep && (
            <li className="nextStep">
              <a href="#" onClick={nextStep}>
                sonraki adım
                <ArrowRight />
              </a>
            </li>
          )}
        </ul>
      </nav>
    </Layout>
  );
};

export const query = graphql`
  query workshopBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      frontmatter {
        title
        description
        lastUpdated(formatString: "DD MMMM YYYY", locale: "tr")
      }
      fields {
        slug
      }
    }
  }
`;
